import List from "../List.js"

export default {
	methods: {
		displayList(listName){
			const listId = this.listNameToId[listName]
			const newList = new List({
				listName,
				listId,
				token: this.user.token,
				QueryParams: {
					order: "user"
				}
			})
			newList.get()
				.then(()=>this.lists.push(newList))
				.catch(this.handleError)
		}
	}
}
