<template>
	<ul
		class="item-list"
		:data-uuid="uuid"
		@drop="onDrop"
		@dragover="onOver"
		@dragenter="onEnter"
		@dragleave="onLeave"
	>
		<ListItem
			v-for="(item, index) in items"
			:key="item._id"
			draggable="true"
			:item="item"
			:list-id-to-name="listIdToName"
			:list-type="listType"
			:list-id="listId"
			@dragstart="drag($event, item, index)"
			@archive="(status)=>$emit('archive', item._id, status)"
			@remove="$emit('remove', item._id)"
			@delete="$emit('delete', item._id)"
			@moveItem="(moveTo)=>$emit('move-item', item._id, moveTo)"
			@copyItem="(copyTo)=>$emit('copy-item', item._id, copyTo)"
			@tags="$emit('tags', index)"
			@action="(item)=>{$emit('action', item)}"
			@openList="(item)=>$emit('open-list', item)"
		/>
		<li v-if="showPlaceholder" id="end" :data-item-id="'zzzzzzzzzzzzzzzzzzzzzzzz'">
			Drop?
		</li>
	</ul>
</template>

<script>

	import ListItem from "./ListItem"
	import {defaultListId} from "nexty-common-code"

	function findItemId(node) {
		if(node.dataset?.itemId) return node.dataset.itemId
		if(node.parentNode) return findItemId(node.parentNode)
		return false
	}

	function getUUIDIfList(node) {
		if(node.dataset?.uuid) return node.dataset.uuid
		if(node.parentNode) return getUUIDIfList(node.parentNode)
		return false
	}

	export default {
		name: "ItemList",
		components: {
			ListItem
		},
		props: {
			items: { type: Array, default: ()=>[] },
			listName: { type: String, default: "" },
			listId: { type: String, default: defaultListId },
			listType: { type: String, default: "" },
			uuid: { type: Number, default: 0 },
			dragOver: { type: Number, default: 0 },
			listIdToName: { type: Object, default: ()=>{}}
		},
		data() {
			return {
				atEnd: false,
				dragStartY: false
			}
		},
		computed: {
			showPlaceholder(){
				return this.dragOver === this.uuid
			},
		},
		methods: {
			drag: function(event, item, index) {

				event.dataTransfer.setData('source info', JSON.stringify({
					sourceList: this.listId,
					sourceItemId: item._id,
				}))
				// Record if we are scrolled to the end of the list
				const self = document.querySelector(`.item-list[data-uuid="${this.uuid}"]`)
				const a = (self?.scrollTop||document.body?.scrollHeight)
				const b = (self?.scrollHeight||document.body?.scrollHeight) - self.clientHeight
				this.atEnd = a===b

				// Record mouse y position at start of scroll
				this.dragStartY = event.pageY

			},
			onDrop: function(event) {
				event.stopPropagation(); // stops the browser from redirecting.
				const destItemId = findItemId(event.target)
				const destListId = this.listId
				const {sourceList, sourceItemId} = JSON.parse(
					event.dataTransfer.getData("source info")
				)
				this.$emit('drag-over', 0) // signals lists to remove drop placeholders

				if(destListId === sourceList){
					// If list is our own, change the item's order within it.
					this.$emit('change-order', sourceList, sourceItemId, destItemId)
				} else {
					// If dropping on a different list call move instead
					this.$emit('move-to-list', {sourceList, sourceItemId, destListId, destItemId})
				}
				return false
			},
			onOver: function(event) {
				event.preventDefault()
				// If drag started at end of scroll and direction is down scroll to last elem.
				// Otherwise placeholder is not always visible without janky auto scrolling.
				if(this.dragStartY && this.atEnd){
					const self = document.querySelector(`.item-list[data-uuid="${this.uuid}"]`)
					if(event.pageY > this.dragStartY){
						self.lastElementChild.scrollIntoView(false)
					}
					this.atEnd = false
					this.dragStartY = false
				}
				return false
			},
			onEnter: function(event) {
				event.preventDefault()
				const overuuid = getUUIDIfList(event.target)
				if(Number(overuuid) === this.uuid) this.$emit('drag-over', this.uuid)
				return false
			}
		}
	}
</script>

<style>
	.item-list{
		display: grid;
		column-gap: 0.5em;
		row-gap: 1em;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		flex-wrap: wrap;
		width: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		min-height: 10em;
		max-height: var(--bottom-height);
		padding-bottom: 0.5em;
		scroll-behavior: smooth;
	}
	#end{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 200px;
		background: white;
	}
</style>