<template>
	<select @change="(event)=>handleSelection(event)">
		<option v-for="(item, index) in options" :key="index">
			{{ item }}
		</option>
	</select>
</template>

<script>
	export default {
		name: "Dropdown",
		props: {
			options: { type: Array, default: ()=>[] },
			selected: { type: String, default: "" },
		},
		methods: {
			handleSelection(event) {
				this.$emit("selection", event.target.value)
			}
		}
	}
</script>

<style>
	select{
		border: 1px solid lightgrey;
		background: #EFEFEF
	}
	option{
		background: white;
	}
</style>