import fetcher from "../helpers/fetcher"

export default {
	methods: {
		// Remove an item from a particular list
		removeItem(listIndex, itemId){
			const list = this.lists[listIndex]
			const {listType, listId } = list
			if(listType==="tag" || listId==="aaaaaaaaaaaaaaaaaaaaaaaa"){
				return console.log(`Remove not implemented for tag lists`)
			}
			fetcher("POST", `/removefromlist`, { listId, itemId }, true)
				.then( () => {
					// Remove card from this particular list
					list.items = list.items.filter(i=>i._id!==itemId)

					// Remove from each instance's list of lists
					this.lists.forEach(list=>{
						const index = list.items.findIndex(item=>item._id===itemId)
						// console.log("ITEM INDEX:", index);
						if(index !== -1){
							console.log("REMOVING LIST ID")
							list.items[index].listIDs = list.items[index].listIDs.filter(i=>i!==listId)
						}
					})
				}).catch(this.handleError)
		}
	}
}