import fetcher from "../helpers/fetcher"
import {errorDialog, disregard} from "../helpers/homepageHelpers"

export default {
	methods: {
		sharedToggle(listIndex){
			console.log(`Toggling shared for ${listIndex}`)
			const {listId} = this.lists[listIndex]
			const status = !this.lists[listIndex].shared
			fetcher("PUT", "/list/public", { listId, status }, true)
				.then( () => {
					this.lists[listIndex].shared = status

					if(!status){
						alert("List is now private.")
						return
					} 
					const user = this.user.id
					const listObject = this.lists[listIndex]
					const list = listObject.listId
					const listName = listObject.listName
					if(listObject.items?.length) {}
					const firstImage = listObject.items[0].image
					
					if(status) console.log(">>>", list, user, listName, firstImage, "<<<")
					
					let payload = `${window.location.protocol}//${window.location.host}/public/list?listId=${list}&userId=${user}`
					if(listName) payload = payload + `&listName=${encodeURIComponent(listName)}`
					payload = payload + "&img=" + encodeURIComponent(firstImage)

					try {
						navigator.clipboard.writeText(payload)
						.then(()=>alert("List shared. Link copied to clipboard"))
					} catch(error) {
						errorDialog(this,	`Copy this link...<br><input value="${payload}"></input>`)
						.catch(disregard)
					}
				})
				.catch(this.handleError)
		}
	}
}
