<template>
	<div class="list-container">
		<div class="list-container-header-bar">
			<span><h1>{{ listName }}</h1> [{{ items.length }}]</span>
			<!-- <span><button>&#10006;</button></span> -->
			<span>

				<!-- Shared toggle -->
				<button
					title="Toggle whether this list is publicly shared or not"
					@click="()=>$emit('shared-toggle')"
				>
					<span class="archive" :class="!shared && 'inactive'">
						<svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 227.216 227.216" style="enable-background:new 0 0 227.216 227.216;" xml:space="preserve"><path d="M175.897,141.476c-13.249,0-25.11,6.044-32.98,15.518l-51.194-29.066c1.592-4.48,2.467-9.297,2.467-14.317 c0-5.019-0.875-9.836-2.467-14.316l51.19-29.073c7.869,9.477,19.732,15.523,32.982,15.523c23.634,0,42.862-19.235,42.862-42.879 C218.759,19.229,199.531,0,175.897,0C152.26,0,133.03,19.229,133.03,42.865c0,5.02,0.874,9.838,2.467,14.319L84.304,86.258 c-7.869-9.472-19.729-15.514-32.975-15.514c-23.64,0-42.873,19.229-42.873,42.866c0,23.636,19.233,42.865,42.873,42.865 c13.246,0,25.105-6.042,32.974-15.513l51.194,29.067c-1.593,4.481-2.468,9.3-2.468,14.321c0,23.636,19.23,42.865,42.867,42.865 c23.634,0,42.862-19.23,42.862-42.865C218.759,160.71,199.531,141.476,175.897,141.476z M175.897,15 c15.363,0,27.862,12.5,27.862,27.865c0,15.373-12.499,27.879-27.862,27.879c-15.366,0-27.867-12.506-27.867-27.879 C148.03,27.5,160.531,15,175.897,15z M51.33,141.476c-15.369,0-27.873-12.501-27.873-27.865c0-15.366,12.504-27.866,27.873-27.866 c15.363,0,27.861,12.5,27.861,27.866C79.191,128.975,66.692,141.476,51.33,141.476z M175.897,212.216 c-15.366,0-27.867-12.501-27.867-27.865c0-15.37,12.501-27.875,27.867-27.875c15.363,0,27.862,12.505,27.862,27.875 C203.759,199.715,191.26,212.216,175.897,212.216z" /></svg>
					</span>
				</button>

				<!-- Archived toggle -->
				<button
					title="Toggle display of archived items"
					@click="()=>$emit('show-archived-toggle')"
				>
					<span class="archive" :class="!showArchived && 'inactive'">
						<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.75 2A1.75 1.75 0 001 3.75v3.5C1 8.216 1.784 9 2.75 9h18.5A1.75 1.75 0 0023 7.25v-3.5A1.75 1.75 0 0021.25 2H2.75zm18.5 1.5H2.75a.25.25 0 00-.25.25v3.5c0 .138.112.25.25.25h18.5a.25.25 0 00.25-.25v-3.5a.25.25 0 00-.25-.25z" /><path d="M2.75 10a.75.75 0 01.75.75v9.5c0 .138.112.25.25.25h16.5a.25.25 0 00.25-.25v-9.5a.75.75 0 011.5 0v9.5A1.75 1.75 0 0120.25 22H3.75A1.75 1.75 0 012 20.25v-9.5a.75.75 0 01.75-.75z" /><path d="M9.75 11.5a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5z" /></svg>
					</span>
				</button>

				<!-- Close button -->
				<button
					title="Close this list"
					@click="$emit('list-close')"
				>
					&#x2715;
				</button>
			</span>
		</div>
		<ListOptions
			:list-type="listType"
			:selected-filter="selectedFilter"
			@filter="(filter)=>$emit('apply-list-filter', filter)"
		/>
		<ItemList
			:items="items"
			:list-type="listType"
			:list-id="listId"
			:uuid="uuid"
			:drag-over="dragOver"
			:list-id-to-name="listIdToName"
			@archive="(itemId, status)=>$emit('archive', itemId, status)"
			@remove="$emit('remove', $event)"
			@delete="$emit('delete', $event)"
			@moveItem="(itemId, moveTo)=>$emit('move-item', {itemId, to:moveTo, from:listName})"
			@moveToList="args=>$emit('move-to-list', args)"
			@copyItem="(itemId, copyTo)=>$emit('copy-item', itemId, copyTo, listName)"
			@tags="$emit('tags', $event)"
			@action="(item)=>$emit('tag-action', item)"
			@openList="(item)=>$emit('open-list', item)"
			@changeOrder="(sourceList, sourceItemId, destItemId)=>$emit('change-order', sourceList, sourceItemId, destItemId, selectedFilter)"
			@dragOver="arg=>$emit('drag-over', arg)"
		/>
	</div>
</template>

<script>
	import {defaultListId} from "nexty-common-code"
	import ListOptions from "./ListOptions"
	import ItemList from "./ItemList"
	import { computed } from 'vue'

	export default {
		name: "ListContainer",
		components: {
			ListOptions,
			ItemList
		},
		provide() {
			const obj = {
				listNames: { value: [] }
			}
			if(!this || !this.allLists) return obj 
			return {
				listNames: computed(()=>{
					const list = this.allLists.filter(i=>i!==this.listName)
					return list
				})
			}
		},
		props: {
			items: { type: Array, default: ()=>[] },
			listName: { type: String, default: "Inbox" },
			listId: { type: String, default: defaultListId },
			allLists: { type: Array, default: ()=>[] },
			listType: { type: String, default: "" },
			showArchived: { type: Boolean, default: false },
			shared: { type: Boolean, default: false },
			selectedFilter: { type: String, default: "user" },
			uuid: { type: Number, default: 0 },
			dragOver: { type: Number, default: 0 },
			listIdToName: { type: Object, default: ()=>{}}
		},
		data() {
			return {
				log: console.log,
			}
		}

	}
</script>
<style scoped>
	h1{
		display: inline-block;
	}
	.options{
		margin-bottom: 0.5em;
	}
	.list-container{
		background: rgb(237, 237, 237);
		border: 1px solid rgb(162, 162, 162);
		box-shadow: 0.33em 0.33em 0.33em grey;
		border-radius: 1em 1em 0 0;
		width: 98%;
		margin: auto;
		padding: 0.5em 0.5em 0.5em 0.5em;
	}
	.list-container > *+*{
		margin-top: 0.5em;
	}
	.list-container-header-bar{
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.list-container-header-bar button{
		height: 1.5em;
		background: light-grey;
		border: 2px solid light-grey;
		border-radius: 0.25em;
		font-size: 1.5em;
		margin-left: 0.25em;
	}
	.archive{
		font-size: 0.7em;
	}
	.list-container-header-bar button:hover{
		color: white;
		background: red;
		border: 2px solid red;
	}
	.inactive{
		opacity: 50%;
	}
</style>