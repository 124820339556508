<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {
				user: {
					authToken: ""
				},
			}
		},
	}
</script>

<style>
	@import './assets/base.css';
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		width: 99.5%;
		margin-left: auto;
		margin-right: auto;
	}
</style>
