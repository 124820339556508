<template>
	<div class="top-bar">

		<span>
			<router-link to="/settings">
				(User Icon) {{ name }}! &#9881;
			</router-link>
		</span>

		<SearchBar
			@search="(term)=>$emit('search', term)"
		/>
		<button @click="handleLogoutClick()">Log out</button>
	</div>
</template>

<script>

import SearchBar from "../components/SearchBar"
export default {
	name: "TopBar",
	components: {
		'SearchBar': SearchBar
	},
	props: {
		name: { type: String, default: "No user found" }
	},
	methods: {
		handleLogoutClick() {
			console.log("handleLogoutClick")
			window.localStorage.removeItem("user")
			this.$router.push("login")
		},
	}
}
</script>

<style scoped>
	.top-bar{
		text-align: left;
		display: flex;
		justify-content: space-between;
		padding: 0.5em 0 0.5em 0;
		height: var(--top-height);
	}
	.settings-icon{
		font-size: 1.5em;
		text-decoration: none;
		color: #555;
	}
</style>