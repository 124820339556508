export default {
	methods: {
		moveToList({sourceList, sourceItemId, destListId, destItemId}){
			if(sourceList && sourceItemId && destListId){
				const args = {
					itemId:sourceItemId,
					toId: destListId,
					fromId: sourceList
				}
				if(destItemId) args.insertId = destItemId
				this.move(args)
			} else {
				console.log("Missing param(s) for moveToList")
			}
		}
	}
}