import fetcher from "../helpers/fetcher"

export default {
	methods: {
		// Set the archive flag on an item
		archiveItem(listIdx, itemId, status){
			console.log("archiveItem::listIdx =", listIdx)
			const {listId} = this.lists[listIdx]
			console.log("archiveItem::listId =", listId)
			console.log("archiveItem::itemId =", itemId)
			console.log("archiveItem::status =", status)
			fetcher("POST", "/archive", { itemId, listId, status }, true)
				.then( res => {

					// 
					const itemIdx = this.lists[listIdx].items.findIndex(item=>{
						return item._id === itemId
					})
					const ail = this.lists[listIdx].items[itemIdx]?.archivedInList
					if(status){
						ail.push(listId)
					} else {
						const ailIdx = ail.findIndex(id=>id===listId)
						ail.splice(ailIdx, 1)
					}

					// See if archived is ticked
					console.log("showArchived:", this.lists[listIdx].showArchived)
					if(this.lists[listIdx].showArchived===false){
						console.log("ITS FALSE - I SHOULD BE DELETING NOW");
						console.log("ITS FALSE - I SHOULD BE DELETING NOW");
						console.log("ITS FALSE - I SHOULD BE DELETING NOW");
						this.lists[listIdx].items = this.lists[listIdx].items.filter(i => i._id !== itemId)
					}

					// Also it doesn't need removing if we are viewing archived items so check that first!
					// Note that show archived items might be set differently on each item
					// Also we may be un-archiving something!

					// TODO - Actually the list could be open multiple times so we need to account for that
					this.lists.forEach((list,index)=>{
						if(list.listId===listId && listIdx !==index){
							console.log("OOH ITS OPEN IN TWO AND THIS IS THE OTHER ONE!");
							list.get()
						}
					})

				}).catch(this.handleError)
		}
	}
}