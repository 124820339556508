import fetcher from "../helpers/fetcher"

export default {
	methods: {
		// Add a tag to an item and run callback if present
		addTag(itemId, tag, callback){
			console.log("addTag mixin called.")
			fetcher("POST", "/addtag", { itemId, tag }, true)
				.then( res => {
					this.lists.forEach(list=>{
						const itemIdx = list.items.findIndex( i=>i._id===itemId )
						if(itemIdx === -1) return console.log("Already got that tag")
						const tags = list.items[itemIdx].tags
						if(tags.includes(tag)) return console.log("Already got that tag") 
						tags.push(tag)
						callback && callback()
						this.tagUsed(tag)
					})
				}).catch(this.handleError)
		}
	}
}