<template>
	<div v-if="state==='loading'">
		<p>Please wait while we verify your email address.</p>
	</div>
	<div v-else-if="state==='error'">
		<p class="error-message">Something went wrong: {{ errMsg }}</p>
	</div>
	<div v-else>
		<p class="error-message">Something truly unexpected happened!</p>
	</div>
</template>

<script>

import fetcher from "../helpers/fetcher"

export default {
	name: "VerifyPage",
	data() {
		return {
			state: "loading",
			errMsg: ""
		}
	},
	async created() {
		const verCode = this.$route.params.code
		fetcher("GET", `/verify/${verCode}`)
		.then(res=>{
			window.localStorage.setItem("user", JSON.stringify(res))
			this.$router.push({ name: "home"})
		}).catch(console.log)
	},
}
</script>

<style scoped>
  .error-message {
    color: #d33c40;
  }
</style>