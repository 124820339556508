import fetcher from "../helpers/fetcher"

export default {
	methods: {
		// Remove tag from an item
		removeTag(itemId, tag){
			fetcher("POST", "/removetag", { itemId, tag }, true)
				.then( () => {
					this.lists.forEach(list=>{
						list.items.forEach(item=>{
							if(item._id===itemId) {
								item.tags = item.tags.filter(i=>i!==tag)
							}
						})
					})
				}).catch(this.handleError)
		}
	}
}