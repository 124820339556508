export const isDigitOrColon = ch=>/^\d|:$/.test(ch)

export function secondsToDHMS(secs){
	if(typeof secs !== typeof 1) return "Unknown length"
	const vals = [], out = []
	const units = [24*60*60, 60*60, 60, 1]
	units.map(unit => {
		const times = Math.floor(secs / unit)
		secs = secs - times * unit
		vals.push(times)
	})
	while(vals[0] === 0) vals.shift()
	if(vals.length) out.push(`${vals.shift()}`)
	while(vals.length) out.push(`${vals.shift()}`.padStart(2,0))
	return out.join(":")
}

export function validToken(){
	try {
		const userAsJSON = window.localStorage.getItem("user")
		// console.log(`userAsJSON: ${userAsJSON}`)
		
		const user = JSON.parse(userAsJSON)
		// console.log(`user: ${user}`)
		
		const dateNow = Date.now()/1000
		// console.log(`dateNow: ${dateNow}`)

		if(!user.token) console.log("No token found")
		// console.log("Token found")
		if(!user.token) throw new Error("No token found")
		
		if(!user.expires) console.log("No expiry date")
		// console.log("Expiry date:", user.expires)
		if(!user.expires) throw new Error("No expiry date")

		// if(dateNow > user.expires) console.log(`Token expired: ${dateNow} > ${user.expires}`)
		if(dateNow > user.expires) throw new Error(`Token expired: ${dateNow} > ${user.expires}`)

		console.log(`Token in storage looks fine. ${secondsToDHMS(user.expires-dateNow)} remaining.`)
		return user.token
	}
	catch(error) {
		console.error(error)
		console.log("Token error:", error.message)
		console.log("Token in localstore is expired or corrupt, deleting.")
		window.localStorage.removeItem("user")
	}
	return false
}

export function arrayInPlaceMove(arr, sourceIdx, destIdx){
    const sourceItem = arr.splice(sourceIdx, 1)[0]
    arr.splice(destIdx, 0, sourceItem)
}

export function UUIDGeneratorFactory() { return (function monotonicCounter(){
	let count = 0
	return function counter(){
		count++
		return count
	}
})()}
