<template>
	<ul class="buttons">
		<li v-if="listType===''"><button title="Show items in user defined order" :class="{'selected': selectedFilter === 'user'}" @click="$emit('filter', 'user')">User</button></li>
		<li><button title="Show oldest items first" :class="{'selected': selectedFilter === 'dateAsc'}" @click="$emit('filter', 'dateAsc')">Oldest</button></li>
		<li><button title="Show newest items first" :class="{'selected': selectedFilter === 'dateDesc'}" @click="$emit('filter', 'dateDesc')">Newest</button></li>
		<li><button title="Show items which have a length, shortest first" :class="{'selected': selectedFilter === 'lenAsc'}" @click="$emit('filter', 'lenAsc')">Shortest</button></li>
		<li><button title="Show items which have a length, longest first" :class="{'selected': selectedFilter === 'lenDesc'}" @click="$emit('filter', 'lenDesc')">Longest</button></li>
	</ul>
</template>

<script>
export default {
	name: "FilterBar",
	props: {
		selectedFilter: { type: String, default: "" },
		listType: { type: String, default: "" }
	}
}
</script>

<style scoped>
	button{
		padding: 0;
		margin: 0;
	}
	.buttons li {
		display: inline;
		margin-right: 0.25em;
		box-shadow: none;
	}
	.buttons button {
		border-radius: 0.25em;
		padding: 0.25em;
		border: 1px solid lightgray;
		box-shadow: none;
		line-height: 0.7em;
	}
	.selected{
		background: white;
		color: black;
	}
</style>