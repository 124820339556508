<template>
	<div id="disqus_thread" />
</template>

<script>
	export default {
		name: "Comments",
		props: {
			pageUrl: { type: String, default: "http://localhost:8080/public/index.html" },
			pageId: { type: String, default: "ABCDE" },
		},

		mounted: function() {
			const PAGE_URL = this.pageUrl;
			const PAGE_IDENTIFIER = this.pageId;
			var disqus_config = function () {
				this.page.url = PAGE_URL;
				this.page.identifier = PAGE_IDENTIFIER;
			};
			(function() {
				var d = document, s = d.createElement('script');
				s.src = 'https://nexty-click.disqus.com/embed.js';
				s.setAttribute('data-timestamp', +new Date());
				(d.head || d.body).appendChild(s);
			})();
		}
	}
</script>
