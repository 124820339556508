/* eslint-disable vue/html-quotes */
<template>
	<li class="listitem" :data-item-id="item._id">

		<!-- Thumbnail -->
		<div class="thumb">
			<a
				title="Click to open in new tab"
				target="_blank"
				rel="noopener noreferrer"
				:href="item.link"
				draggable="false"
			>
				<img
					:alt="'Link to ' + item.title"
					class="thumbnail"
					:src="item.image ? item.image : require(`../assets/nexty.svg`)"
					loading="lazy"
				>
			</a>
		</div>

		<!-- Runtime overlay -->
		<div v-if="item.runtime" class="runtime">
			<span>{{ secondsToDHMS(item.runtime) }}</span>
		</div>

		<div class="details">

			<!-- Action Buttons -->
			<ul class="buttons">

				<!-- Archive -->
				<li
					v-if="listType!=='tag'"
					title="Hide this item from view but keep it in the list. It can be displayed again with the 'show archive' button"
				>
					<button @click="$emit('archive', archived)">
						{{ archived ? "Archive" : "Un-archive" }}
					</button>
				</li>

				<!-- Remove -->
				<li
					v-if="listType!=='tag'"
					title="Remove this item from this list (but not any others it is in)"
				>
					<button @click="$emit('remove')">
						Remove
					</button>
				</li>

				<!-- Delete -->
				<li
					title="Completely delete this item from the system"
				>
					<button @click="$emit('delete')">
						Delete
					</button>
				</li>

				<!-- Tags -->
				<li
					title="Add or Remove this item's tags"
				>
					<button @click="$emit('tags')">
						Tags
					</button>
				</li>

				<!-- Move dropdown -->
				<li>
					<select
						v-if="listType!=='tag' && listNames?.length"
						title="Move this item to a different list"
						@change="(event)=>move(event)"
					>
						<option selected>Move to</option>
						<option
							v-for="li in listNames"
							:key="li"
							:value="li"
						>
							{{ li }}
						</option>
					</select>
				</li>

				<!-- Copy dropdown -->
				<li>
					<select
						v-if="listNames?.length"
						v-model="copySelected"
						title="Copy this item to a different list"
						@change="(event)=>copy(event)"
					>
						<option selected>Copy to</option>
						<option
							v-for="li in listNames"
							:key="li"
							:value="li"
						>
							{{ li }}
						</option>
					</select>
				</li>


			</ul>	

			<!-- Item Channel Details -->
			<h1>{{ item.title }}</h1>
			<p v-if="item.channelLink" class="channel">
				<a :href="item.channelLink" draggable="false">
					{{ item.domain }} :: {{ item.channelName }}
				</a>
			</p>
			<p v-if="!item.channelLink" class="channel">
				{{ item.domain }}
				<span v-if="item.channelName"> :: {{ item.channelName }}</span>
			</p>

			<!-- List Membership-->
			<TagList
				:tags="memberOf()"
				:show-close="false"
				:outline="true"
				@action="(item)=>{$emit('open-list', item)}"
			/>

			<!-- Item Tags -->
			<TagList
				:tags="item.tags"
				:show-close="false"
				@action="(item)=>{$emit('action', item)}"
			/>

		</div>
	</li>

</template>

<script>
	import {defaultListId} from "nexty-common-code"
	import TagList from "./TagList"
	import {secondsToDHMS} from "../misc.js"

	export default {
		name: "ListItem",
		components: {
			TagList
		},
		inject: ['listNames'],
		props: {
			item: { type: Object, default: ()=>{} },
			listType: { type: String, default: "" },
			listId: { type: String, default: defaultListId },
			listIdToName: { type: Object, default: ()=>{} }
		},
		data() {
			return {
				secondsToDHMS,
				copySelected: "Copy to"
			}
		},
		computed: {
			archived() {
				if(this.listId && this.item?.archivedInList?.includes(this.listId)) return false
				return true
			}
		},
		methods: {
			move(event) {
				this.$emit("move-item", event.target.value)
			},
			copy(event) {
				this.$emit("copy-item", event.target.value)
				// Once copy is initiated selected item needs to be reset to "Copy to"
				// We do this by binding that element to the model and changing it back here
				this.copySelected = "Copy to"
			},
			memberOf() {
				const m = this.item.listIDs.map(i => this.listIdToName[i])
				// console.log(m)
				return m
			}
		}
	}
</script>
<style scoped>
	h1 {
		font-size: 1em;
	}
	.listitem {
		width: 100%;
		height: 100%;
		background: white;
		border-radius: 0.5em;
		overflow: hidden;
		box-shadow: 0.33em 0.33em 0.33em grey;
	}
	img {
		/* width: 100%; */
		/* max-height: 11.5em; */
		width: 300px;
		height: 225px;
		object-fit: cover;

	}
	.details{
		padding: 0.25em 0.5em 0.5em 0.5em;
	}
	.details > *+* {
		margin-top: 0.5em;
	}
	.runtime{
		z-index: 0;
		padding-right: 0.5em;
		position: relative;
		top: -30px;
		margin-bottom: -20px;
		width: 100%;
		text-align: right;
		color: white;
   		text-shadow:
			-1px -1px 0 #000,  
			1px -1px 0 #000,
			-1px 1px 0 #000,
			1px 1px 0 #000;
	}
	.runtime span {
		padding: 0em 0.25em 0em 0.25em;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 0.25em;
	}
	.buttons li {
		display: inline;
		margin-right: 0.25em;
		box-shadow: none;
	}
	.buttons button {
		border-radius: 0.25em;
		/* color: grey; */
		padding: 0.25em;
		border: 1px solid lightgray;
		box-shadow: none;
		line-height: 0.7em;
	}
	.channel {
		color: blue;
	}
	select option:first-child {
		display: none;
	}
	select {
		font-size: 0.8em;
		border: 1px solid light-grey;
		border-radius: 0.25em;
		/* color: grey; */
		padding: -1em;
		max-width: 6em;
	}
</style>