import fetcher from "../helpers/fetcher"

export default {
	methods: {
		createNewList(listName){
			this.newListModalOn = false
			fetcher("POST", `/newlist/${listName}`, {}, true)
				.then( res => {
					if(!res.listId) throw new Error(`Missing list Id`)
					this.listNames.unshift(listName)
					this.listNameToId[listName] = res.listId
					this.listIdToName[res.listId] = listName
					this.displayList(listName)
				}).catch(this.handleError)
		}
	}
}