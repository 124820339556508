import fetcher from "../helpers/fetcher"

export default {
	methods: {
		// Copy an item to a different list
		copy(itemId, to, from){
			console.log(`itemId:${itemId} to:${to} from:${from}`)
			if(!itemId || !to || !from) return console.log("Copy requested but parameter(s) missing.")
			const sourceId = this.listNameToId[from]
			const destId = this.listNameToId[to]
			const sourceListIdx = this.lists.findIndex(list=>list.listId===sourceId)
			const sourceList = this.lists[sourceListIdx].items
			const itemIdx = sourceList.findIndex(i=>i._id===itemId)
			const item = sourceList[itemIdx]
			fetcher("PUT", `/list/copy`, { itemId, destId }, true)
				.then( () => {
					// Add item to instances of dest list if not already present
					this.lists.forEach(list=>{
						if(list.listId===destId){
							if(list.items.findIndex(item=>item._id===itemId)===-1){ list.items.unshift(item) }
						}
					})
					// Update listIDs array for each instance of this item
					this.lists.forEach(list=>{
						const index = list.items.findIndex(item=>item._id===itemId)
						// console.log("INDEX:", index)
						if(index !== -1){
							if(!list.items[index].listIDs.includes(destId)){
								list.items[index].listIDs.push(destId)
								console.log("ADDING LIST ID")
							}
						}
					})
					// Move dest list to head of list of lists
					const listNameIdx = this.listNames.indexOf(to)
					if(listNameIdx > 0){
						this.listNames.splice(listNameIdx,1)
						this.listNames.unshift(to)
					}
				})
				.catch(this.handleError)
		}
	}
}