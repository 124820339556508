export const user = {
	token: ""
}
export const logLev = 3

const {VUE_APP_LOCAL_IP} = process.env
const ip = VUE_APP_LOCAL_IP || "localhost"

let api = `http://${ip}:3333`
let ws = `ws://${ip}:3333`

if(process.env.VUE_APP_LOCALDB !== "TRUE"){
	const {VUE_APP_APIENDPOINT, VUE_APP_WSENDPOINT} = process.env
	// console.log("Using REMOTE backend:", VUE_APP_APIENDPOINT)
	if(VUE_APP_APIENDPOINT) api = VUE_APP_APIENDPOINT
	if(VUE_APP_WSENDPOINT) ws = VUE_APP_WSENDPOINT
} else {
	// console.log("Using LOCAL backend:", api)
}

export const apiEndpoint = api
export const webSocketEndpoint = ws