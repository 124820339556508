import List from "../List.js"

export default {
	methods: {
		displayTagBasedList(tag){
			const newList = new List({
				listName: tag,
				listId: "aaaaaaaaaaaaaaaaaaaaaaaa",
				listType: "tag",
				token: this.user.token,
				QueryParams: {
					onlys: [tag],
				}
			})
			newList.get()
				.then(()=>this.lists.push(newList))
				.catch(this.handleError)
		}
	}
}