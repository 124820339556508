<template>
	<ModalOverlay
		bottom-close=""
		@close="$emit('close')"
	>
		<template #body>

			<form>
				<input
					ref="input"
					v-model="listName"
					title="Enter new list name"
					placeholder="Enter new list name"
				>

				<button type="submit" @click="submitHandler">
					Submit
				</button>

				<button type="button" @click="$emit('close')">
					Cancel
				</button>
			</form>

		</template>
	</ModalOverlay>
</template>

<script>
import ModalOverlay from './ModalOverlay.vue'
export default {
	name: "NewListDialog",
	components: { ModalOverlay },
	data() { return {
		listName: ""
	}},
	mounted() {
		this.$refs.input.focus()
	},
	methods: {
		close() {
			this.$emit('close')
		},
		submitHandler(event) {
			this.$emit("submit", this.listName)
		}
	},
}
</script>

<style scoped>

	ul {
		padding: 0;
    	list-style-type: none;
	}

	.modal-backdrop {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.modal {
		background: #FFFFFF;
		box-shadow: 2px 2px 20px 1px;
		overflow-x: auto;
		display: flex;
		flex-direction: column;
	}

	.modal-header,
	.modal-footer {
		padding: 15px;
		display: flex;
	}

	.modal-header {
		border-bottom: 1px solid #eeeeee;
		color: #4AAE9B;
		justify-content: space-between;
	}

	.modal-footer {
		border-top: 1px solid #eeeeee;
		justify-content: flex-end;
	}

	.modal-body {
		position: relative;
		padding: 20px 10px;
	}

	.tag {
		display: inline-flex;
		/* display: flex; */
		color: white;
		background: red;
	}
  
	.recently-used {
		padding-top: 1em;
		color: grey;
	}

	.search-add-form button {
		margin-left: 0.5em;
		border: 1px solid grey;
		border-radius: 0.25em;
		padding: 0;
		width: 3em;
	}


	.search-add-form button,
	.search-add-form input
	{
		margin-top: 1em;
		height: 2em;
	}
	.error{
		border: 2px solid red;
	}

</style>