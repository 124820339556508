/* eslint-disable vue/no-v-html */
<template>
	<ModalOverlay bottom-close=""
		@close="$emit('close')"
		@keydown.y="$emit('yes')"
		@keydown.n="$emit('no')"
	>
		<template #body>

			<div>
				<span v-html="text" />
			</div>

			<div class="buttons">
				<button v-if="yesButtonText" @click="$emit('yes')">
					{{ yesButtonText }}
				</button>
				<button v-if="noButtonText" ref="nope" @click="$emit('no')">
					{{ noButtonText }}
				</button>
			</div>

		</template>
	</ModalOverlay>
</template>

<script>
import ModalOverlay from './ModalOverlay.vue'
export default {
	name: "ConfirmDialog",
	components: { ModalOverlay },
	props: {
		text: { type: String, default: "Do you want to proceed?" },
		yesButtonText: { type: String, default: "" },
		noButtonText: { type: String, default: "" },
	},
	mounted() {
		if(this.$refs.nope) this.$refs.nope.focus()
	},
	methods: {
		close() {
			this.$emit('close')
		},
	},
}
</script>

<style scoped>
	.buttons {
		margin-top: 1em;
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.buttons > *+* { margin-left: 1em;}
</style>