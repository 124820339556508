<template>
	<ul v-if="tags.length" :class="direction" class="container">
		<li v-for="item in tags" :key="item" class="tag" :style="getCol(item)">

			<div
				:title="bodyTitle"
				@click="$emit('action', item)"
			>
				{{ item }}
			</div>&nbsp;

			<div v-if="showAdd"
				:title="addTitle"
			>
				|<button
					class="tag-button"
					@click="$emit('add', item)"
				>
					&#43;
				</button>
			</div>

			<div v-if="showClose"
				:title="closeTitle"
			>
				|<button
					class="tag-button"
					@click="$emit('close', item)"
				>
					&times;
				</button>
			</div>

		</li>
	</ul>
</template>

<script>
	export default {
		name: "TagList",
		props: {
			tags: { type: Array, default: ()=>{ return [] } },
			direction: { type: String, default: "horizontal" },
			showClose: { type: Boolean, default: true },
			closeTitle: { type: String, default: "" },
			bodyTitle: { type: String, default: "" },
			showAdd: { type: Boolean, default: false },
			addTitle: { type: String, default: "" },
			outline: { type: Boolean, default: false }
		},
		methods: {
			getCol(name){

				// console.log("TAG NAME WE ARE TRYING TO COLOUR IS...", name, typeof name)
				// I don't get why we are being called with an undefined name here
				// The only code that calls it should only run when the list length > 0
				// And WTF are we iterating through if we're getting undefineds ?!?
				if(name===undefined) return`
					background: #000;
					border: 0.25em solid red;
				`

				const sum = name.split("").reduce( (a,v)=>a+v.charCodeAt(0), 0)
				const col = sum % 360
				const sat = 95
				const lig = 70 + (sum % 20)
				if(this.outline){
					return `
						background: #FFF;
						padding: 0.25em;
						border: 1px solid hsl(${col},${sat}%,${lig-40}%);
					`
				} 
				return `
					background: hsl(${col},${sat}%,${lig}%);
					border: 0.25em solid hsl(${col},${sat}%,${lig}%);
				`
			}
		}
	}
</script>

<style scoped>
	ul {
		padding: 0;
    	list-style-type: none;
		display: flex;
		justify-content: left;
		align-items: center;
	}
	.tag {
		display: flex;
		align-items: center;
		/* color: white; */
		margin-right: 0.5em;
		border-radius: 0.25em;
		height: 1.5em;
		white-space: nowrap;
		margin-bottom: 0.25em;
	}
	.tag-button {
		width: 1em;
		height: 1em;
		padding: 0;
		border: 0;
		margin: 0;
		background: transparent;
		/* color: white; */
	}
	.tag:hover {
		cursor: pointer;
		text-shadow: 0px 0px 1px white;
	}
	.tag-button:hover {
		text-shadow: 0px 0px 1px white;
	}
	.vertical {
		flex-direction: column;
		align-items: flex-start;
	}
	.vertical > *+* {
		margin-top:0.5em;
	}
	span {
		padding: 0;
		margin: 0;
	}
	.container{
		/* border: 1px solid red; */
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -0.25em;

	}
</style>