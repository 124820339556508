import {apiEndpoint, logLev} from "../settings.js"
import {err} from "nexty-common-code"

export default function fetcher(method, route, payload, token){
	const url = `${apiEndpoint}${route}`
	// console.log(`Fetching: ${url}`)

	const options = {
		headers: {
			"content-type": "application/json"
		},
		method
	}
	if(payload && Object.keys(payload).length>0) options.body = JSON.stringify(payload)

	if(token) {
		const userAsJSON = window.localStorage.getItem("user")
		const user = JSON.parse(userAsJSON)
		options.headers.Authorization = `Bearer ${user.token}`
	} else {
		console.log("Auth not requested")
	}

	return fetch(url , options)
	.then( res => res.json() )
	.then( (res) => {
		// logLev>1 && console.log(`fetcher got a reply for our req to ${url}`, res)
		const {code, msg} = res
		if(isNaN(code) || code < 200 || code > 299){
			throw new err(code, msg)
		}
		return res
	})
}