import {apiEndpoint} from "./settings"
import {UUIDGeneratorFactory} from "./misc"
const newUUID = UUIDGeneratorFactory()

export default class List {
	constructor({listName, listId, listType, token, QueryParams={}}) {
		this.listName = listName
		this.listType = listType || ""
		this.listId = listId
		this.showArchived = false // ?????
		this.QueryParams = QueryParams
		this.token = token
		this.items = []
		this.uuid = newUUID()
		this.shared = false
	}
	_makeParams() {
		const paramArr = []
		const order = this.QueryParams.order
		const onlys = this.QueryParams.onlys
		const has = this.QueryParams.has
		const hasnt = this.QueryParams.hasnt
		// Not sure if we ever need to override the Lists own property
		// but here it is anyway, remove when arsed to check 
		const archive = this.QueryParams.archive

		if(archive || this.showArchived) paramArr.push("showArchived=true")
		if(order) paramArr.push(`sort=${order}`)
		if(onlys && onlys.length>0) {
			paramArr.push(`only=${onlys.join(",")}`)
		}

		if(has && has.length>0) {
			paramArr.push(`has=${has.join(",")}`)
		}
		if(hasnt && hasnt.length>0) {
			paramArr.push(`hasnt=${hasnt.join(",")}`)
		}

		const paramStr = paramArr.join("&")
		return paramStr ? `?${paramStr}` : ""
	}
	get() {
		const URLparams = this._makeParams()
		const query = encodeURI(`${apiEndpoint}/list/${this.listId}${URLparams}`)
		// console.log("SENDING GET QUERY TO:", query)
		return fetch(query, {
			headers: {
				"Authorization": `Bearer ${this.token}`,
				"content-type": "application/json"
			},
		})
		.then( res => res.json() )
		.then( res => {
			// console.log(`We got a reply to our req for /list/${this.listId}`, res)
			if(res.code !== 200){
				let errMsg = new Error(`Fail code: ${res.code}`)
				if(res.msg) errMsg.message = res.msg
				if(res.code) errMsg.code = res.code
				throw errMsg
			}
			this.items = res.items
			this.shared = res.shared
		})
	}
}