<template>
	<ModalOverlay top-title="Tags..."
		@close="$emit('close')"
	>
		<template #body>

			<TagList
				close-title="Remove this tag from the item"
				:tags="currentTags"
				@close="removeTag"
			/>

			<div class="recently-used">Recently used...</div>
			<TagList
				body-title="Add this tag to the item"
				:tags="localtags"
				:show-close="false"
				@action="chooseTag"
			/>

			<form class="search-add-form" @submit.prevent="submitHandler">
				<input
					ref="input"
					v-model="inputVal"
					:class="inputError ? 'error' : ''"
					name="tagInput"
					title="Type the name of the tag you would like to add"
					placeholder="Search or add new tag..."
				>
				<button type="submit">OK</button>
			</form>

		</template>
	</ModalOverlay>
</template>

<script>
import TagList from './TagList.vue'
import ModalOverlay from './ModalOverlay.vue'
export default {
	name: "TagDialog",
	components: { ModalOverlay, TagList},
	props: {
		parentId: { type: String, default: "No id provided, boo!" },
		currentTags: { type: Array, default: ()=>{ return [] } },
		recentTags: { type: Array, default: ()=>{ return [] } },
	},
	data() { return {
		localtags: [...this.recentTags],
		inputVal: "",
		inputError: false
	}},
	mounted() {
		this.$refs.input.focus()
	},
	methods: {
		close() {
			this.$emit('close')
		},
		removeTag(tagName){
			if( // Re-add to choices if it came from choices
				!this.localtags.includes(tagName)
				&& this.recentTags.includes(tagName)
			) {
				this.localtags.push(tagName)
			}
			this.$emit('remove-tag', this.parentId, tagName)
		},
		chooseTag(tagName){
			this.localtags = this.localtags.filter(i=>i!==tagName)
			this.$emit('add-tag', this.parentId, tagName)
		},
		submitHandler() {

			this.inputVal = this.inputVal.toLowerCase()

			this.$emit('add-tag', this.parentId, this.inputVal, (err) => {
				// TODO do next bit after API call success - but how
				console.log("IN EMIT CALLBACK")
				if(err) {
					console.log("THERE WAS AN ERROR! AN ERROR I TELL YOU!", err)
					this.inputError = true
				} else {
					console.log("SUCCESS! NO ERRORS HERE")
					this.inputError = false
					this.inputVal = ""
				}
			})
		}
	},
}
</script>

<style scoped>

	ul {
		padding: 0;
    	list-style-type: none;
	}

	.modal-backdrop {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.modal {
		background: #FFFFFF;
		box-shadow: 2px 2px 20px 1px;
		overflow-x: auto;
		display: flex;
		flex-direction: column;
	}

	.modal-header,
	.modal-footer {
		padding: 15px;
		display: flex;
	}

	.modal-header {
		border-bottom: 1px solid #eeeeee;
		color: #4AAE9B;
		justify-content: space-between;
	}

	.modal-footer {
		border-top: 1px solid #eeeeee;
		justify-content: flex-end;
	}

	.modal-body {
		position: relative;
		padding: 20px 10px;
	}

	.tag {
		display: inline-flex;
		/* display: flex; */
		color: white;
		background: red;
	}
  
	.recently-used {
		padding-top: 1em;
		color: grey;
	}

	.search-add-form button {
		margin-left: 0.5em;
		border: 1px solid grey;
		border-radius: 0.25em;
		padding: 0;
		width: 3em;
	}


	.search-add-form button,
	.search-add-form input
	{
		margin-top: 1em;
		height: 2em;
	}
	.error{
		border: 2px solid red;
	}

</style>