import { errorDialog, disregard } from "../helpers/homepageHelpers"

export default {
	methods: {
		handleError(err) {
			console.error(err)
			console.log("ERROR:", err.message)
			let errMsg = `<h2>${err.message}</h2><br>`
			if(err.code) errMsg = errMsg + `<br>error: ${err.code}`
			errorDialog(this, errMsg)
				.then(disregard)
		}
	}
}