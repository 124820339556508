import {apiEndpoint} from "../settings.js"

export function getTags(token){
	const query = `${apiEndpoint}/tags`
	return fetch(query, {
		headers: {
			"Authorization": `Bearer ${token}`,
			"content-type": "application/json"
		},
	})
	.then( res => res.json() )
	.then( res => {
		if(res.code !== 200) throw new Error(`Fail code: ${res.code}`)
		return res.tags
	})
}

export function getListOfLists(token){
	const query = `${apiEndpoint}/list`
	return fetch(query, {
		headers: {
			"Authorization": `Bearer ${token}`,
			"content-type": "application/json"
		},
	})
	.then( res => res.json() )
	.then( res => {
		if(res.code !== 200) throw new Error(`Fail code: ${res.code}`)
		return res.lists
	})
}

export function confirmDialog(that, msg, callback){
	return new Promise( (resolve,reject) => {
		that.confirmModalOn = true
		that.confirmationText = msg
		that.confirmationNoButtonText = "No"
		that.confirmationYesButtonText = "Yes"
		that.confirmationNoHandler = ()=>{
			that.confirmModalOn = false
			that.confirmationText = ""
			that.confirmationNoHandler = undefined
			that.confirmationYesHandler = undefined
			reject()
		}
		that.confirmationYesHandler = ()=>{
			that.confirmModalOn = false
			that.confirmationText = ""
			that.confirmationNoHandler = undefined
			that.confirmationYesHandler = undefined
			resolve()
		}
	})
}

export function errorDialog(that, msg, callback){
	return new Promise( (resolve,reject) => {
		that.confirmModalOn = true
		that.confirmationText = msg
		that.confirmationNoButtonText = undefined
		that.confirmationYesButtonText = "OK"
		that.confirmationNoHandler = ()=>{
			that.confirmModalOn = false
			that.confirmationText = ""
			that.confirmationNoHandler = undefined
			that.confirmationYesHandler = undefined
			reject()
		}
		that.confirmationYesHandler = ()=>{
			that.confirmModalOn = false
			that.confirmationText = ""
			that.confirmationNoHandler = undefined
			that.confirmationYesHandler = undefined
			resolve()
		}
	})
}

export function disregard(){}