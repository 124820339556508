export default {
	methods: {
		applyListFilter(listIndex, filter){
			console.log("FILTER", listIndex, filter)
			this.lists[listIndex].QueryParams.order = filter
			this.lists[listIndex]
				.get()
				.catch(this.handleError)
		}
	}
}