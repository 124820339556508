import {defaultListId} from "nexty-common-code"
import List from "../List.js"

export default {
	methods: {
		displayDefaultList(){
			const newList = new List({
				listName: "Inbox",
				listId: defaultListId,
				token: this.user.token,
				QueryParams: {
					order: "dateDesc"
				}
			})
			newList.get()
				.then( () => {this.lists.push(newList)})
				.catch(this.handleError)
		}
	}
}