import List from "../List.js"

export default {
	methods: {
		siteSearch(term){
			console.log(`SEARCH TERM: ${term}`)
			let has = []
			let hasnt = []
			const terms = term.split(" ")
			terms.forEach(t => {
				if(t.length>1 && t[0] === "-") hasnt.push(t.slice(1))
				if(t.length>0 && t[0] !== "-") has.push(t)
			})
			if(has.length + hasnt.length > 0){
				const newList = new List({
					listName: term,
					listId: "aaaaaaaaaaaaaaaaaaaaaaaa",
					listType: "tag",  // Change to search?
					token: this.user.token,
					QueryParams: {
						has,
						hasnt
					}
				})
				newList.get()
					.then(()=>this.lists.push(newList))
					.catch(this.handleError)
			}
		}
	}
}