import fetcher from "../helpers/fetcher"
import {arrayInPlaceMove} from "../misc"

export default {
	methods: {
		// Move an item to a different place in a particular list
		changeOrder(listId, itemId, destItemId, sortOrder){
			if(listId === "aaaaaaaaaaaaaaaaaaaaaaaa"){
				alert("Sorry. You can only change the order of manually created lists, not tag list or search results.")
			} else if(sortOrder !== "user") {
				alert('You can only change the order of items when the sort order is set to "user" (at the top of the list).')
			} else {
				this.lists.forEach(list=>{
					if(list.listId === listId) {
						const sourceIdx = list.items.findIndex(item => item._id === itemId)
						let destIdx = list.items.findIndex(item => item._id === destItemId)
						if(destItemId === "zzzzzzzzzzzzzzzzzzzzzzzz") destIdx = list.items.length
						fetcher("PUT", `/list/moveitemtoindex`, {itemId, listId, destIdx}, true)
						.then(_=>{
							arrayInPlaceMove(list.items, sourceIdx, destIdx)
							// Move dest list to head of list of lists
							const to = this.listIdToName[listId]
							const listNameIdx = this.listNames.indexOf(to)
							if(listNameIdx > 0){
								this.listNames.splice(listNameIdx,1)
								this.listNames.unshift(to)
							}
						})
						.catch(this.handleError)
					}
				})
			}
		}
	}
}
