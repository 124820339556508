<template>
	<h1>Welcome to nexty</h1>
	<div id="nav">
		<router-link to="/login">Login</router-link>
		<router-link to="/signup"> Signup</router-link>
	</div>
</template>

<script>
	export default {
		name: "LandingPage"
	}
</script>