import fetcher from "../helpers/fetcher"

export default {
	methods: {
		
		// Move and item to a different list, and optionally place in that list

		move({itemId, to, from, fromId, toId, insertId}){
			// 'to' and 'from' are list names
			// 'toId' and 'fromId' are 24 char listIds
			
			const sourceId = this?.listNameToId[from] || fromId
			const destId = this?.listNameToId[to] || toId
			const sourceList = this.lists.filter(list=>list.listId===sourceId)[0]
			const actualItem = sourceList.items.filter(item=>item._id===itemId)[0]

			 // item dropped at end. Unsetting insertId saves last item id lookup
			const after = insertId === "zzzzzzzzzzzzzzzzzzzzzzzz"
			if(after) insertId = undefined

			fetcher("PUT", `/list/move`, { itemId, sourceId, destId, insertId, after }, true)
				.then( () => {
					/* 
						For each open instance of the drop target list... Proceed if the item isn't already there, otherwise bail. If an insert ID is provided, find it's index and splice the new entry before it by default, or after it if 'after' is truthy. If no indexId is provided add new item to bottom if 'after' is set, or the top if it isn't.
					*/
					this.lists.forEach(list=>{
						if(list.listId===destId){
							if(list.items.findIndex(item=>item._id===itemId)===-1){
								const idx = list.items.findIndex(item=>item._id===insertId)
								if(idx === -1){
									if(after) {
										list.items.push(actualItem)
									} else {
										list.items.unshift(actualItem)
									}
								} else {
									if(after) idx++
									list.items.splice(idx, 0, actualItem)
								}
							}
						}
					})
					// Remove the item from it's source lists
					this.lists.forEach(list=>{
						if(list.listId===sourceId){
							const idx = list.items.findIndex(i=>i._id===itemId)
							list.items.splice(idx, 1)
						}
					})
					// Move dest list to head of list of lists
					const listNameIdx = this.listNames.indexOf(to)
					if(listNameIdx > 0){
						this.listNames.splice(listNameIdx,1)
						this.listNames.unshift(to)
					}
				}).catch(this.handleError)
		}
	}
}