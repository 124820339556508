<template>
	<div class="modal-backdrop">
		<div class="modal">

			<header v-if="topTitle||topRightClose" class="modal-header">
				<span v-if="topTitle">{{ topTitle }}</span>
				<button v-if="topRightClose"
					type="button"
					class="btn-close"
					@click="$emit('close')"
				>
					X
				</button>
			</header>

			<section class="modal-body">
				<slot name="body">
					I'm the default body
				</slot>
			</section>

			<footer v-if="bottomClose">
				<button
					type="button"
					class="close-button"
					@click="$emit('close')"
				>
					{{ bottomClose }}
				</button>
			</footer>

		</div>
	</div>
</template>

<script>
	export default {
		name: "ModalOverlay",
		props: {
			topTitle: { type: String, default: "" },
			topRightClose: { type: Boolean, default: false },
			bottomClose: { type: String, default: "Done"}
		},
		created() {
			document.addEventListener('keyup', this.keyHandler)
		},
		destroyed() {
			document.removeEventListener('keyup', this.keyHandler)
		},
		methods: {
			keyHandler(event) {
				if (event.keyCode === 27) {
					this.$emit('close')
				}
			},
			close() {
				this.$emit('close')
			}
		},
	}
</script>

<style scoped>

	.modal-backdrop {
		z-index: 1;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.modal {
		position: absolute;
		background: white;
		box-shadow: 2px 2px 20px 1px;
		overflow-x: auto;
		display: flex;
		flex-direction: column;
		padding: 1em;
	}

	.modal-header,
	.modal-footer {
		display: flex;
	}

	.modal-header {
		font-size: 1.5em;
		font-weight: bold;
		justify-content: space-between;
		margin-bottom: 0.5em;
	}

	.modal-footer {
		justify-content: flex-end;
	}

	.modal-body {
		position: relative;
	}

	.btn-close {
		border: 0.125em solid red;
		border-radius: 0.125em;
		font-size: 0.75em;
		cursor: pointer;
		font-weight: bold;
		color: white;
		background: red;
	}

	.close-button{
		margin-top: 1em;
		width: 100%;
		height: 2em;
		background: blue;
		color: white;
		border: 0.125em solid blue;
		border-radius: 0.125em;
		font-weight: bold;
		font-size: 1.25em;
	}

</style>