<template>
	<h1>Sign up for an account!</h1>

	<div v-if="confirmModalOn">
		<ConfirmDialog
			:text="confirmationText"
			:no-button-text="confirmationNoButtonText"
			:yes-button-text="confirmationYesButtonText"
			@close="confirmationNoHandler"
			@no="confirmationNoHandler"
			@yes="confirmationYesHandler"
		/>
	</div>
	
	<div v-if="success===false" id="signup-form">

		<form @submit.prevent="handleSubmit">

			<!-- Name field -->
			<label for="name">Username</label>
			<input
				id="name"
				v-model="user.name"
				type="text"
				:class="{ 'has-error': submitting && invalidName }"
				@focus="clearStatus"
				@keypress="clearStatus"
			>

			<!-- Email field -->
			<label for="email">Email</label>
			<input
				id="email"
				ref="email"
				v-model="user.email"
				type="text"
				:class="{ 'has-error': submitting && invalidEmail }"
				@focus="clearStatus"
				@keypress="clearStatus"
			>

			<!-- Password field -->
			<label for="password">Password</label>
			<input
				id="password"
				v-model="user.password"
				type="password"
				:class="{ 'has-error': submitting && invalidPassword }"
				@focus="clearStatus"
				@keypress="clearStatus"
			>

			<!-- Error instructions -->
			<p v-if="error && submitting" class="error-message">
				{{ errMsg }}
			</p>

			<button>Create Account</button><br>
			Go back to <router-link to="/login"> Login</router-link>

		</form>
	</div>
	<div v-else>
		<p>Submission successful, please check your email and follow the link to verify your account.
		</p></div>
</template>

<script>

import fetcher from "../helpers/fetcher"
import handleError from "../mixins/homepage_handleError"
import ConfirmDialog from "../components/ConfirmDialog"

export default {
	name: "SignupForm",
	components: {
		'ConfirmDialog': ConfirmDialog
	},
	mixins: [handleError],
	data() {
		return {
			submitting: false,
			error: false,
			errMsg: "So there was a problem with that, please try again",
			success: false,
			user: {
				name: '',
				email: "",
				password: "",
			},
			confirmModalOn: false,
			confirmationText: "",
			confirmationNoButtonText: "No",
			confirmationYesButtonText: "Yes",
			confirmationNoHandler: undefined,
			confirmationYesHandler: undefined
		}
	},
	computed: {
		invalidName() {
			return this.user.name === ''
		},
		invalidEmail() {
			return this.user.email === ''
		},
		invalidPassword() {
			return this.user.password === ''
		}
	},
	methods: {
		clearStatus() {
			this.success = false,
			this.error = false
		},
		handleSubmit() {
			this.submitting = true
			this.clearStatus()
			console.log("Signup Submit clicked!")
			
			if (this.invalidName || this.invalidEmail || this.invalidPassword){
				this.error = true
				if(this.invalidName) this.errMsg = "There's a problem with that name"
				if(this.invalidEmail) this.errMsg = "There's a problem with that email"
				if(this.invalidPassword) this.errMsg = "There's a problem with that password"
				return
			}
			
			// Send creds to API
			fetcher("POST", "/signup", this.user)
			.then(()=>{
				this.error = false
				this.success = true
				this.submitting = false
			}).catch(this.handleError)
		}
	},
}
</script>

<style scoped>
	@import '../assets/auth_pages.css';
</style>