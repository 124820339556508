<template>
	<div class="lists-header-bar">
		<h2>Lists</h2>
		<button title="Create a new list" @click="$emit('list-new')">New</button>
	</div>
	<Dropdown
		title="Sort your lists by: most recently used, alphabetical or least recently used"
		:options="listsSortOptions"
		:selected="listsSortOrder"
		@selection="updateListsSortOrder"
	/>
	<TagList
		body-title="Show this list (and close other open lists)"
		:tags="listList"
		:direction="direction"
		:show-add="true"
		add-title="Open list alongside other open lists"
		:show-close="true"
		close-title="Delete list from system"
		@action="(item)=>{$emit('list-action', item)}"
		@add="(item)=>{$emit('list-also', item)}"
		@close="(item)=>{$emit('list-delete', item)}"
	/>
	<br>
	<h2>Tags</h2>
	<Dropdown
		title="Sort your tags by: most recently used, alphabetical or least recently used"
		:options="tagsSortOptions"
		:selected="tagsSortOrder"
		@selection="updateTagsSortOrder"
	/>
	<TagList
		body-title="Show list of items with this tag (and close other open lists)"
		:tags="tagList"
		:direction="direction"
		:show-add="true"
		add-title="Open tag list alongside other open lists"
		:show-close="true"
		close-title="Delete tag from system"
		@action="(item)=>{$emit('tag-action', item)}"
		@add="(item)=>{$emit('tag-also', item)}"
		@close="(item)=>{$emit('tag-delete', item)}"
	/>
</template>

<script>

import TagList from "../components/TagList"
import Dropdown from "../components/Dropdown"

export default {
	name: "SideBar",
	components: {
		'TagList': TagList,
		'Dropdown': Dropdown,
	},
	props: {
		lists: { type: Array, default: ()=>{ return [] } },
		tags: { type: Array, default: ()=>{ return [] } },
	},
	emits: ["tag-action", "tag-also", "tag-delete", "list-item", "list-also", "list-delete", "list-action", "list-new"],
	data() { return {
			// note: default sortOrder must be first in sortOptions
			direction: "vertical",
			listsSortOrder: "most recently used",
			listsSortOptions: ["most recently used", "alphabetical", "least recently used"],
			tagsSortOrder: "most recently used",
			tagsSortOptions: ["most recently used", "alphabetical", "least recently used"],
	}},
	computed: {
		tagList() {
			if(this.tagsSortOrder === "most recently used") return this.tags
			if(this.tagsSortOrder === "least recently used") return [...this.tags].reverse()
			return [...this.tags].sort()
		},
		listList() {
			if(this.listsSortOrder === "most recently used") return this.lists
			if(this.listsSortOrder === "least recently used") return [...this.lists].reverse()
			return [...this.lists].sort()
		}
	},
	methods: {
		updateTagsSortOrder(order) {this.tagsSortOrder = order},
		updateListsSortOrder(order) {this.listsSortOrder = order}
	}
}
</script>

<style scoped>
	h2 {
		font-size: 1.3em;
	}
	.lists-header-bar{
		display: flex;
		justify-content: left;
		padding-bottom: 0.5em;
	}
	button {
		padding: 0 0.5em;
		margin: 0 0 0 0.5em;
		border-radius: 0.25em;
		background:white;
	}
	button:hover {
		background: white;
	}
</style>