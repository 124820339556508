import fetcher from "../helpers/fetcher"
import {confirmDialog, disregard} from "../helpers/homepageHelpers"

export default {
	methods: {
		// Delete an item entirely i.e. from every list in the whole system.
		deleteItem(itemId){
			confirmDialog(this,	"Are you sure you want to delete this item from the system? It will be deleted from ALL of your lists!")
			.then( () => fetcher("POST", "/delete", { itemId }, true)
				.then( res => {
					this.lists.forEach(list=>{
						list.items = list.items.filter(i => i._id !== itemId)
					})
				}).catch(this.handleError)
			).catch(disregard)
		}
	}
}