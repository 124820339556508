import {validToken} from "./misc.js"
import LoginForm from "@/components/LoginForm.vue"
import SignupForm from "@/components/SignupForm.vue"
import LandingPage from "@/components/LandingPage.vue"
import HomePage from "@/components/HomePage.vue"
import VerifyPage from "@/components/VerifyPage.vue"
import SettingsPage from "@/components/SettingsPage.vue"
import PublicList from "@/components/PublicList.vue"
import {createRouter, createWebHistory} from "vue-router"

let router = createRouter({
	history: createWebHistory(),
	routes: [
		{path: "/login", name: "login", component: LoginForm},
		{path: "/signup", name: "signup", component: SignupForm},
		{path: "/verify/:code", name: "verify", component: VerifyPage},
		{
			path: "/",
			name: "home",
			component: HomePage,
			beforeEnter(_to, _from, next) {
				if(validToken()) {
					next()
				} else {
					console.log("No valid token for route /, redirecting to login.")
					next({name: "login"})
				}
			}
		},
		{
			path: "/settings",
			name: "settings",
			component: SettingsPage,
			beforeEnter(_to, _from, next) {
				if(validToken()) {
					next()
				} else {
					console.log("No valid token for route /, redirecting to login.")
					next({name: "login"})
				}
			}
		},
		{path: "/landing", name:"landing", component: LandingPage},
		{path: "/public/list", name:"publiclist", component: PublicList},
		{path: "/:catchAll(.*)", redirect: () => "/landing"}
	],
})

export default router