<template>
	<h1>Log in to Nexty</h1>

	<div v-if="confirmModalOn">
		<ConfirmDialog
			:text="confirmationText"
			:no-button-text="confirmationNoButtonText"
			:yes-button-text="confirmationYesButtonText"
			@close="confirmationNoHandler"
			@no="confirmationNoHandler"
			@yes="confirmationYesHandler"
		/>
	</div>

	<div id="login-form">

		<form @submit.prevent="handleSubmit">

			<!-- Email field -->
			<label for="email">Email</label>
			<input
				id="email"
				ref="email"
				v-model="user.email"
				type="text"
				:class="{ 'has-error': submitting && invalidName }"
				@focus="clearStatus"
				@keypress="clearStatus"
			>

			<!-- Password field -->
			<label for="password">Password</label>
			<input
				id="password"
				v-model="user.password"
				type="password"
				:class="{ 'has-error': submitting && invalidName }"
				@focus="clearStatus"
				@keypress="clearStatus"
			>

			<!-- Error instructions -->
			<p v-if="error && submitting" class="error-message">
				{{ errMsg }}
			</p>

			<button>Login</button><br>
			No account yet? <router-link to="/signup"> Signup</router-link>
		</form>
	</div>
</template>

<script>

import fetcher from "../helpers/fetcher"
import handleError from "../mixins/homepage_handleError"
import ConfirmDialog from "../components/ConfirmDialog"

export default {
	name: 'LoginForm',
	components: {
		'ConfirmDialog': ConfirmDialog
	},
	mixins: [handleError],
	data() {
		return {
			submitting: false,
			error: false,
			errMsg: "So there was a problem with that, please try again",
			success: false,
			user: {
				email: "",
				password: "",
			},
			confirmModalOn: false,
			confirmationText: "",
			confirmationNoButtonText: "No",
			confirmationYesButtonText: "Yes",
			confirmationNoHandler: undefined,
			confirmationYesHandler: undefined
		}
	},
	computed: {
		invalidName() {
			return this.user.name === ''
		},
		invalidEmail() {
			return this.user.email === ''
		}
	},
	methods: {
		clearStatus() {
			this.success = false,
			this.error = false
		},
		handleSubmit() {
			this.submitting = true
			this.clearStatus()
			
			if (this.invalidEmail || this.invalidPassword){
				this.error = true
				if(this.invalidEmail) this.errMsg = "There's a problem with that email"
				if(this.invalidPassword) this.errMsg = "There's a problem with that password"
				return
			}
			fetcher("POST", `/login`, this.user)
			.then(res=>{
				window.localStorage.setItem("user", JSON.stringify(res))
				this.$router.push({ name: "home"})
			})
			.catch((error)=>{
				this.handleError(error)
			})
		}
	}
}
</script>

<style scoped>
	@import '../assets/auth_pages.css';
</style>
