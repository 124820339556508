<template>
	<ul>
		<li
			v-for="(item, index) in items"
			:key="index"
			class="listitem"
		>
			<!-- Thumbnail -->
			<div class="thumb">
				<a
					title="Click to open in new tab"
					target="_blank"
					rel="noopener noreferrer"
					:href="item.link"
					draggable="false"
				>
					<img
						:alt="'Link to ' + item.title"
						class="thumbnail"
						:src="item.image ? item.image : require(`../assets/nexty.svg`)"
						loading="lazy"
					>
				</a>

				
			</div>
			<span>{{ item.title }}</span>
			<!-- {{ index }} - {{ JSON.stringify(item) }} -->
		</li>
	</ul>
</template>

<script>
	export default {
		name: "PublicItemList",
		props: {
			view: {type: String, default: "normal"},
			items: {type: Array, default: ()=>[]}
		}
	}
</script>

<style scoped>
	img {
		width: 8em;
		min-width: 8em;
		height: 6em;
		object-fit: cover;
		vertical-align: middle;
	}
	span {
		margin-left: 1em;
	}
	.listitem {
		display: flex;
		align-items: center;
		margin: 1em 0 1em 0;
		padding: 0.25em;
		width: 100%;
		border-radius: 0.25em;
		overflow: hidden;
		box-shadow: 0.33em 0.33em 0.33em grey;
	}

</style>