import fetcher from "../helpers/fetcher"
import {confirmDialog, disregard} from "../helpers/homepageHelpers"

export default {
	methods: {
		// Delete a tag completely i.e. from every item in the system
		tagDelete(tag){
			confirmDialog(this,	"Are you sure you want to delete this tag from every item in the system?")
			.then( () => fetcher("DELETE", `/deletetag/${tag}`, {}, true)
				.then( () => {
					// Remove tag from master tag list
					this.tags = this.tags.filter(i=>i!==tag)
					// If tag list open for that tag remove it
					this.lists = this.lists.filter(list=>{
						const lQPo = list.QueryParams?.onlys
						if(lQPo?.length === 1 && lQPo[0] === tag) return false
						return true
					})
					// Remove the tag from any open items that have it
					this.lists.forEach(list=>{
						list.items.forEach(item=>{
							item.tags = item.tags.filter(i=>i!==tag)
						})
					})
				}).catch(this.handleError)
			).catch(disregard)
		}
	}
}