import {webSocketEndpoint} from "./settings"

class WSServer {

	constructor (token) {
		this.token = token
		this.ping = JSON.stringify({ ping: true })
		this.timeoutMS = 120000
		this.allowedLatency = 1000
		this.connection = false
		this.alive = false
		this.sender = false
		this.checker = false
		this.check = this.check.bind(this)
		this.reset = this.reset.bind(this)
		this.connect = this.connect.bind(this)
		this.reconnect = this.reconnect.bind(this)
		this.messageHandler = this.messageHandler.bind(this)
		this.handlers = {}
		this.connect()
	}

	on(eventName, handlerFunction){
		this.handlers[eventName] = handlerFunction
	}

	reconnect(backOff) {
		console.log("Reconnecting to websocket server")
		const maxTime = 1000*60*2
		this.connection = this.connect(()=>{
			if(backOff > maxTime) return this.reset("Couldn't connect", 1000*60*2)
			this.reset("Couldn't connect", backOff * 1.5)
		})		
	}

	reset(msg, backOff){
		console.log("Resetting", msg, backOff/1000,"seconds")
		clearInterval(this.checker)
		if(typeof this.connection === "object"){
			if(this.connection.hasOwnProperty("terminate")) this.connection.terminate()
		}
		this.connection = false
		setTimeout( this.reconnect, backOff, backOff )
		return true
	}

	check(){
		this.alive = false
		// console.log("Checking connection")
		if(!this.connection) return this.reset("No connection to test", 5000)
		// console.log("Sending ping to server.", this.ping)
		this.connection.send(this.ping)
		// console.log(`Ping sent ${Date()}`)
		this.sender = setTimeout( ()=>{
			if(!this.alive){
				return this.reset("Pong not received after 2s", 5000)
			}
		}, this.allowedLatency )
	}

	messageHandler(event) {
		const {info, error, message, payload} = JSON.parse(event.data)
		// info && console.log('INFO:', info)
		// error && console.log('ERROR:', error)
		if(message) {
			// if(message!=="pong") console.log('MESSAGE:', message)
			// payload && console.log('PAYLOAD:', payload)
			if(message==="registered") {
				// console.log("Registered with server")
				this.checker = setInterval( this.check, this.timeoutMS )
			}
			if(message==="pong") {
				// console.log("Pong recvd from server", Date())
				this.alive = true
			}
			if(this.handlers[message]) this.handlers[message](payload)
		}
	}

	// connect(onError=()=>{console.log("Can't connect")}){
	connect(){
		this.connection = new WebSocket(webSocketEndpoint)
		this.connection.addEventListener('open', event => {
			this.connection.send(JSON.stringify({ token: this.token }))
		})
		this.connection.addEventListener('message', this.messageHandler)
		// this.connection.addEventListener('error', onError)
		// this.connection.addEventListener('close', function (event) {
		// 	console.log('Socket closed', event.data)
		// 	// TODO reconnect with exponential backoff
		// })
		return this.connection
	}

}

export default WSServer