<template>

	<div v-if="tagsModalOn">
		<TagDialog
			:current-tags="lists[tagInQuestion.list].items[tagInQuestion.item].tags"
			:recent-tags="tags.slice(0,10)"
			:parent-id="lists[tagInQuestion.list].items[tagInQuestion.item]._id"
			@close="handleTagsClose"
			@remove-tag="removeTag"
			@add-tag="addTag"
			@tag-used="tagUsed"
		/>
	</div>

	<div v-if="confirmModalOn">
		<ConfirmDialog
			:text="confirmationText"
			:no-button-text="confirmationNoButtonText"
			:yes-button-text="confirmationYesButtonText"
			@close="confirmationNoHandler"
			@no="confirmationNoHandler"
			@yes="confirmationYesHandler"
		/>
	</div>

	<div v-if="newListModalOn">
		<NewListDialog
			@submit="createNewList"
			@close="()=>newListModalOn = false"
		/>
	</div>

	<!-- Edit item details modal -->

	<TopBar
		:name="user?.name"
		@search="siteSearch"
	/>

	<div class="main-container">

		<div class="side-bar">
			<SideBar
				:lists="listNames"
				:tags="tags"
				@tag-action="displayTagBasedListAfterClearing"
				@tag-also="displayTagBasedList"
				@tag-delete="tagDelete"
				@list-action="displayListAfterClearing"
				@list-also="displayList"
				@list-delete="deleteList"
				@list-new="()=>newListModalOn = true"
			/>
		</div>

		<div class="list-area">
			<div v-if="lists.length < 1" class="item">Loading...</div>
			<div v-else class="masterContainer">
				<div v-for="(list, listIndex) in lists" :key="list.listName" class="parentContainer">
					<ListContainer
						:uuid="list.uuid"
						:list-name="list.listName"
						:list-id="list.listId"
						:all-lists="listNames"
						:items="list.items"
						:list-type="list.listType"
						:shared="list.shared"
						:show-archived="list.showArchived"
						:selected-filter="list.QueryParams.order"
						:drag-over="dragOver"
						:list-id-to-name="listIdToName"
						@archive="(item, status)=>archiveItem(listIndex, item, status)"
						@remove="item=>removeItem(listIndex, item)"
						@delete="item=>deleteItem(item)"
						@tags="item=>openTagDialog(listIndex, item)"
						@tag-action="displayTagBasedList"
						@list-close="()=>listClose(listIndex)"
						@moveItem="move"
						@moveToList="(abc)=>moveToList(abc)"
						@copyItem="copy"
						@shared-toggle="()=>sharedToggle(listIndex)"
						@show-archived-toggle="()=>showArchivedToggle(listIndex)"
						@apply-list-filter="filter=>applyListFilter(listIndex, filter)"
						@changeOrder="changeOrder"
						@dragOver="(arg)=>dragOver=arg"
						@openList="displayList"
					/>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {defaultListId} from "nexty-common-code"
	import {secondsToDHMS} from "../misc.js"
	import WSServer from "../wsserver.js"
	import {getTags, getListOfLists} from "../helpers/homepageHelpers"

	import TopBar from "../components/TopBar"
	import ListContainer from "../components/ListContainer"
	import SideBar from "../components/SideBar"
	import TagDialog from "../components/TagDialog"
	import ConfirmDialog from "../components/ConfirmDialog"
	import NewListDialog from "../components/NewListDialog"

	import addTag from "../mixins/homepage_addTag"
	import applyListFilter from "../mixins/homepage_applyListFilter"
	import archiveItem from "../mixins/homepage_archiveItem"
	import moveToList from "../mixins/homepage_moveToList"
	import changeOrder from "../mixins/homepage_changeOrder"
	import copy from "../mixins/homepage_copy"
	import createNewList from "../mixins/homepage_createNewList"
	import deleteItem from "../mixins/homepage_deleteItem"
	import deleteList from "../mixins/homepage_deleteList"
	import displayDefaultList from "../mixins/homepage_displayDefaultList"
	import displayList from "../mixins/homepage_displayList"
	import displayListAfterClearing from "../mixins/homepage_displayListAfterClearing"
	import displayTagBasedList from "../mixins/homepage_displayTagBasedList"
	import displayTagBasedListAfterClearing from "../mixins/homepage_displayTagBasedListAfterClearing"
	import handleError from "../mixins/homepage_handleError"
	import handleTagsClose from "../mixins/homepage_handleTagsClose"
	import listClose from "../mixins/homepage_listClose"
	import move from "../mixins/homepage_move"
	import openTagDialog from "../mixins/homepage_openTagDialog"
	import removeItem from "../mixins/homepage_removeItem"
	import removeTag from "../mixins/homepage_removeTag"
	import siteSearch from "../mixins/homepage_siteSearch"
	import sharedToggle from "../mixins/homepage_sharedToggle"
	import showArchivedToggle from "../mixins/homepage_showArchivedToggle"
	import tagDelete from "../mixins/homepage_tagDelete"
	import tagUsed from "../mixins/homepage_tagUsed"

	export default {
		name: 'HomePage',
		components: {
			'TopBar': TopBar,
			'SideBar': SideBar,
			'TagDialog': TagDialog,
			'ConfirmDialog': ConfirmDialog,
			'NewListDialog': NewListDialog,
			'ListContainer': ListContainer,
		},
		mixins: [
			createNewList,
			addTag,
			applyListFilter,
			archiveItem,
			changeOrder,
			copy,
			deleteItem,
			deleteList,
			displayDefaultList,
			displayList,
			displayListAfterClearing,
			displayTagBasedList,
			displayTagBasedListAfterClearing,
			handleError,
			handleTagsClose,
			listClose,
			move,
			moveToList,
			openTagDialog,
			removeItem,
			removeTag,
			siteSearch,
			sharedToggle,
			showArchivedToggle,
			tagDelete,
			tagUsed
		],
		data() {
			return {
				dragOver: 0,
				user: "",
				items: [],
				lists: [],
				listNameToId: {"default": defaultListId},
				listIdToName: {"default": "Inbox"},
				listNames: ["default"],
				log: console.log,
				tags: [],
				secondsToDHMS,
				console,
				newListModalOn: false,
				tagsModalOn: false,
				tagInQuestion: false,
				confirmModalOn: false,
				confirmationText: "",
				confirmationNoButtonText: "No",
				confirmationYesButtonText: "Yes",
				confirmationNoHandler: undefined,
				confirmationYesHandler: undefined
			}
		},
		async created() {

			// ON CREATED
			// ON CREATED
			// ON CREATED

			const userAsJSON = window.localStorage.getItem("user")
			const user = JSON.parse(userAsJSON)
			this.user = user

			// Only run when we have a user record
			if(user){

				// WEBSOCKET STUFF
				// WEBSOCKET STUFF
				// WEBSOCKET STUFF
				this.socket = new WSServer(this.user.token)
				this.socket.on("reload-list", (listId)=>{
					const lists = this.lists.filter(i=>i.listId===listId)
					lists.forEach( (list) => { list
						.get()
						.catch(this.handleError)
					})
				})
				this.socket.on("deleted-item", (itemId)=>{
					this.lists.forEach( list => {
						list.items = list.items.filter(i=>i._id!==itemId)
					})
				})
				this.socket.on("tags", ()=>getTags(this.user.token))
				// WEBSOCKET STUFF ENDS
				// WEBSOCKET STUFF ENDS
				// WEBSOCKET STUFF ENDS

				this.displayDefaultList()

				// REQUEST LIST OF TAGS
				getTags(this.user.token).then( (miTags)=>{
					this.tags = miTags
				})

				// REQUEST LIST OF LISTS
				getListOfLists(this.user.token).then( (miList)=>{
					this.listNameToId = {}
					this.listIdToName = {}
					this.listNames = []
					miList.forEach(list=>{
						this.listNames.push(list.listName)
						this.listNameToId[list.listName] = list.listId
						this.listIdToName[list.listId] = list.listName
					})
				})
			} else {
				console.log("No user record found, can't call API.")
			}

		},
	}
</script>

<style>
	.main-container{
		display: flex;
		width: 100%;
	}
	.masterContainer{
		display: flex;
		width: 100%;
	}
	.parentContainer{
		justify-content: space-between;
		padding-top: 0.5em;
		/* overflow: scroll; */
	}
	.list-area{
		display: flex;
		width: 100%;
	}
	.list-area div{
		flex: 1
	}
	div .side-bar{
		height: var(--sidebar-height);
		overflow-y: scroll;
		overflow-x: hidden;
	}
</style>
