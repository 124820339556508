<template>
	<div class="pageFrame">
		<div class="contentFrame">
			<div v-if="error !== ''" class="err">
				<span>ERROR: {{ error }}</span>
			</div>
			<div v-else>
				<h1>{{ listName }}</h1>
				<PublicItemList
					:view="viewType"
					:items="items"
				/>
				<br>
				<hr>
				<Comments
					:page-url="url"
					:page-id="pageId"
				/>

			</div>
		</div>
	</div>
</template>

<script>
	import fetcher from "../helpers/fetcher"
	import {mongoIdRegex} from 'nexty-common-code'
	import PublicItemList from "../components/PublicItemList"
	import Comments from "../components/Comments"

	function justNums(url){
		const parts = url.split(/[=&]/)
		return parts[1] + parts[3]
	}

	export default {
		name: "PublicList",
		components: {
			'PublicItemList': PublicItemList,
			'Comments': Comments,
		},
		data() {
			return {
				error: "",
				viewType: "normal",
				listName: "",
				items: [],
				url: window.location.href,
				pageId: justNums(window.location.href)
			}
		},
		async created() {
			const userId = this.$route.query.userId
			const listId = this.$route.query.listId
			if(
				mongoIdRegex.test(userId) === null ||
				mongoIdRegex.test(listId) === null
			){
				this.error = "Invalid URL parameters."
				return
			}
			const params = `?listId=${listId}&userId=${userId}`
			fetcher("GET", `/public/list` + params)
			.then(res=>{
				// console.log("GOTTTTTTT:", res)
				if(Array.isArray(res?.items) === false){
					this.error = "Malformed data"
					return
				}
				this.listName = res?.listName
				this.items = res.items
			})
			.catch(this.handleError)
		}
	}
</script>

<style scoped>
	.pageFrame{
		height: 100%;
		width: 100%;
		height: 100vh;
		background: grey;
	}
	.contentFrame{
		max-width: 800px;
		background: white;
		margin: auto;
		padding: 1em;
	}
	h1{
		font-size: 1.5rem;
	}
</style>