import fetcher from "../helpers/fetcher"

export default {
	methods: {
		// Notify API tag has been used, put tag at top of list
		tagUsed(tag){
			const idx=this.tags.indexOf(tag)
			if(idx!==-1) this.tags.splice(idx,1)
			this.tags.unshift(tag)
			fetcher("POST", "/tagused", { tag }, true)
				.catch(this.handleError)
		}
	}
}