<template>
	<div>
		<form class="search-add-form" @submit.prevent="submitHandler">
			<label for="search" class="visually-hidden">Search Box </label>
			<input
				id="search"
				v-model="term" title="Search all items in all lists. Doublequoted terms are ANDed, other terms are ORed."
			>
			<button type="submit">Search</button>
		</form>
	</div>
</template>

<script>
export default {
	name: "SearchBar",
	data() {
		return {
			term: ""
		}
	},
	methods: {
		submitHandler() {
			this.$emit('search', this.term)
		}
	}
}
</script>

<style scoped>
	form {
		display: inline-block;
		height: 100%;
	}
	form input{
		width: 20em;
		font-size: 1.6em;
		height: 100%;
	}
	form button{
		height: 100%;
		vertical-align: top;
		margin-top: 0px !important;
	}
	.visually-hidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}
</style>