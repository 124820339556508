export default {
	methods: {
		showArchivedToggle(listIndex){
			console.log(`Toggling showArchived for ${listIndex}`)
			this.lists[listIndex].showArchived = !this.lists[listIndex].showArchived
			this.lists[listIndex]
				.get()
				.catch(this.handleError)
		}
	}
}