import fetcher from "../helpers/fetcher"
import {confirmDialog, disregard} from "../helpers/homepageHelpers"

export default {
	methods: {
		// Delete a list entirely i.e. from the whole system
		deleteList(listName){
			const listId = this.listNameToId[listName]
			confirmDialog(this,	"This operation cannot be undone. Are you sure you want to delete this list?")
			.then(() => fetcher("DELETE", `/list/delete/${listId}`, {}, true)
				.then( res => {
					delete this.listNameToId[listName]
					this.listNames = this.listNames.filter(l=>l!==listName)
					this.lists = this.lists.filter(l=>l.listId!==listId)					
				}).catch(this.handleError)
			).catch(disregard)
		}
	}
}