<template>
	<h1>Settings</h1>

	<div v-if="confirmModalOn">
		<ConfirmDialog
			:text="confirmationText"
			:no-button-text="confirmationNoButtonText"
			:yes-button-text="confirmationYesButtonText"
			@close="confirmationNoHandler"
			@no="confirmationNoHandler"
			@yes="confirmationYesHandler"
		/>
	</div>

	<hr>
	<form autocomplete="off" @submit.prevent="changePassword">

		<label for="oldPassword">Current password: </label>
		<input
			id="oldPassword"
			ref="pwdChgCurrent"
			v-model="pwdChg.old"
			type="password"
			autocomplete="new-password"
		>
		<div id="pwdChgCurrentHelp" class="error">
			{{ pwdChg.oldHelp }}
		</div>
		<br>

		<label for="newPassword">New password: </label>
		<input
			id="newPassword"
			v-model="pwdChg.new"
			type="password"
		>
		<div id="pwdChgNewHelp" class="error">
			{{ pwdChg.newHelp }}
		</div>
		<br>

		<label for="newPasswordAgain">New password again: </label>
		<input
			id="newPasswordAgain"
			v-model="pwdChg.again"
			type="password"
		>
		<div id="pwdChgNewAgainHelp" class="error">
			{{ pwdChg.againHelp }}
		</div>
		<br>

		<button type="submit">Change Password</button>

	</form>
	<hr>

</template>

<script>

	import fetcher from "../helpers/fetcher"
	import handleError from "../mixins/homepage_handleError"
	import ConfirmDialog from "../components/ConfirmDialog"

	export default {
		name: "SettingsPage",
		components: {
			'ConfirmDialog': ConfirmDialog
		},
		mixins: [handleError],
		data() {
			return {
				pwdChg: {
					old: "",
					oldHelp: "",
					new: "",
					newHelp: "",
					again: "",
					againHelp: "",
				},
				confirmModalOn: false,
				confirmationText: "",
				confirmationNoButtonText: "No",
				confirmationYesButtonText: "Yes",
				confirmationNoHandler: undefined,
				confirmationYesHandler: undefined
			}
		},
		methods: {
			changePassword() {

				console.log("Change password clicked!")

				this.pwdChg.oldHelp = ""
				this.pwdChg.newHelp = ""
				this.pwdChg.againHelp = ""

				if(this.pwdChg.old.length === 0) this.pwdChg.oldHelp = "Field cannot be empty."
				if(this.pwdChg.new.length === 0) this.pwdChg.newHelp = "Field cannot be empty."
				if(this.pwdChg.again.length === 0) this.pwdChg.againHelp = "Field cannot be empty."
				if(this.pwdChg.new.length < 10) this.pwdChg.newHelp = "Passwords should be at least 10 characters long."
				if(this.pwdChg.again.length < 10) this.pwdChg.againHelp = "Passwords should be at least 10 characters long."
				if(this.pwdChg.new !== this.pwdChg.again) this.pwdChg.againHelp = "Passwords do not match."

				if(
					this.pwdChg.oldHelp
					|| this.pwdChg.newHelp
					|| this.pwdChg.againHelp
				) return

				const payload = {
					currentPassword: this.pwdChg.old,
					newPassword: this.pwdChg.new
				}
				fetcher("PUT", `/changepassword`, payload, true)
				.then(res=>{
					window.localStorage.setItem("user", JSON.stringify(res))
					this.$router.push({ name: "home"})
				})
				.catch(this.handleError)
			}
		}
	}

</script>